@require '/src/dev'
/*

    Fancy Check and Radio 

    Markup examples

    <div class="input-toggle">
        <input type="checkbox" id="fancycheckbox1">
        <label for="fancycheckbox1">
            <span />Nothing
        </label>
    </div>




*/
.input-toggle
    & input
        padding: 0
        margin: 0
        height: 26px
        width: 26px
        float: left
        position: absolute
        left: 0
        opacity: 0

    & label 
        cursor: pointer
        color: #767676
        display: block
        padding: 8px 0
        line-height: 26px
        font-size: 18px
        +bp(min768)
            line-height: 20px
    
    & input + label > span    
        display: inline-block
        vertical-align: top
        margin-right: 8px
        background-repeat: no-repeat
        background-position: center center
        background-size: cover
        width: 26px
        height: 26px
        +bp(min768)
            width: 18px
            height: 18px

     &--is-disabled
        & label
            color: $sardine
            & > span
                opacity: 0.25
            
        
    & input[type="checkbox"]
        // Mobile empty
        & + label > span 
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 26 26'%3E%3Cdefs%3E%3Crect id='a' width='22' height='22' rx='2'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate%282 2%29'%3E%3Cuse fill='%23FFF' xlink:href='%23a'/%3E%3Crect width='24' height='24' x='-1' y='-1' stroke='%23767676' stroke-width='2' rx='2'/%3E%3C/g%3E%3C/svg%3E") 
 
        // Mobile Focused
        &:focus + label > span,
        &:active + label > span
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 26 26'%3E%3Cdefs%3E%3Crect id='a' width='22' height='22' rx='2'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate%282 2%29'%3E%3Cuse fill='%23FFF' xlink:href='%23a'/%3E%3Crect width='24' height='24' x='-1' y='-1' stroke='%2306BEFB' stroke-width='2' rx='2'/%3E%3C/g%3E%3C/svg%3E")
 
        // Mobile Checked
        &:checked + label > span
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 26 26'%3E%3Cdefs%3E%3Crect id='a' width='22' height='22' x='2' y='2' rx='2'/%3E%3Cpath id='b' d='M4.42 7.6L3 9.03l4.45 4.47 7.05-7.07L13.08 5l-5.63 5.64z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cuse fill='%23767676' xlink:href='%23a'/%3E%3Crect width='24' height='24' x='1' y='1' stroke='%23767676' stroke-width='2' rx='2'/%3E%3Cg transform='translate%284 4%29'%3E%3Cpath d='M0 0h18v18H0z'/%3E%3Cmask id='c' fill='%23fff'%3E%3Cuse xlink:href='%23b'/%3E%3C/mask%3E%3Cuse fill='%23767676' fill-rule='nonzero' xlink:href='%23b'/%3E%3Crect width='18' height='18' rx='2' fill='%23FFF' mask='url%28%23c%29'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
 
        // Mobile Checked and focused
        &:checked:focus + label > span,
        &:checked:active + label > span
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='26' viewBox='0 0 26 26'%3E%3Cdefs%3E%3Crect id='a' width='22' height='22' x='2' y='2' rx='2'/%3E%3Cpath id='b' d='M4.42 7.6L3 9.03l4.45 4.47 7.05-7.07L13.08 5l-5.63 5.64z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cuse fill='%2306BEFB' xlink:href='%23a'/%3E%3Crect width='24' height='24' x='1' y='1' stroke='%2306BEFB' stroke-width='2' rx='2'/%3E%3Cg transform='translate%284 4%29'%3E%3Cpath d='M0 0h18v18H0z'/%3E%3Cmask id='c' fill='%23fff'%3E%3Cuse xlink:href='%23b'/%3E%3C/mask%3E%3Cuse fill='%2306BEFB' fill-rule='nonzero' xlink:href='%23b'/%3E%3Crect width='18' height='18' rx='2' fill='%23FFF' mask='url%28%23c%29'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")

    & input[type="radio"]
        // Mobile empty 
        & + label > span
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiI+ICAgIDxkZWZzPiAgICAgICAgPGNpcmNsZSBpZD0iYSIgY3g9IjEzIiBjeT0iMTMiIHI9IjExIi8+ICAgIDwvZGVmcz4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDx1c2UgZmlsbD0iI0ZGRiIgeGxpbms6aHJlZj0iI2EiLz4gICAgICAgIDxjaXJjbGUgY3g9IjEzIiBjeT0iMTMiIHI9IjEyIiBzdHJva2U9IiM3Njc2NzYiIHN0cm9rZS13aWR0aD0iMiIvPiAgICA8L2c+PC9zdmc+")

        // Mobile Focused
        &:focus + label > span,
        &:active + label > span
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiI+ICAgIDxkZWZzPiAgICAgICAgPGNpcmNsZSBpZD0iYSIgY3g9IjEzIiBjeT0iMTMiIHI9IjExIi8+ICAgIDwvZGVmcz4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDx1c2UgZmlsbD0iI0ZGRiIgeGxpbms6aHJlZj0iI2EiLz4gICAgICAgIDxjaXJjbGUgY3g9IjEzIiBjeT0iMTMiIHI9IjEyIiBzdHJva2U9IiMwNkJFRkIiIHN0cm9rZS13aWR0aD0iMiIvPiAgICA8L2c+PC9zdmc+")

        // Mobile Checked
        &:checked + label > span
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiI+ICAgIDxkZWZzPiAgICAgICAgPGNpcmNsZSBpZD0iYSIgY3g9IjEzIiBjeT0iMTMiIHI9IjExIi8+ICAgIDwvZGVmcz4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDx1c2UgZmlsbD0iI0ZGRiIgeGxpbms6aHJlZj0iI2EiLz4gICAgICAgIDxjaXJjbGUgY3g9IjEzIiBjeT0iMTMiIHI9IjEyIiBzdHJva2U9IiM3Njc2NzYiIHN0cm9rZS13aWR0aD0iMiIvPiAgICAgICAgPGNpcmNsZSBjeD0iMTMiIGN5PSIxMyIgcj0iOCIgZmlsbD0iIzc2NzY3NiIvPiAgICA8L2c+PC9zdmc+")

        // Mobile Checked and focused
        &:checked:focus + label > span,
        &:checked:active + label > span
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiI+ICAgIDxkZWZzPiAgICAgICAgPGNpcmNsZSBpZD0iYSIgY3g9IjEzIiBjeT0iMTMiIHI9IjExIi8+ICAgIDwvZGVmcz4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDx1c2UgZmlsbD0iI0ZGRiIgeGxpbms6aHJlZj0iI2EiLz4gICAgICAgIDxjaXJjbGUgY3g9IjEzIiBjeT0iMTMiIHI9IjEyIiBzdHJva2U9IiMwNkJFRkIiIHN0cm9rZS13aWR0aD0iMiIvPiAgICAgICAgPGNpcmNsZSBjeD0iMTMiIGN5PSIxMyIgcj0iOCIgZmlsbD0iIzA2QkVGQiIvPiAgICA8L2c+PC9zdmc+")

